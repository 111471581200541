<template>
  <div class="radio-tabs">
    <div class="radio-group">
      <label
        v-for="option in optionsTabs"
        :key="option.value"
        class="radio-group-button"
        v-model="selectedOption"
        @change="optionChanged"
        :class="{ active: selectedOption === option.value }"
      >
        <input
          type="radio"
          :value="option.value"
          v-model="selectedOption"
          class="hidden-input"
        />
        {{ option.label }}
      </label>
    </div>
    <!--<p>Selected: {{ selectedOption }}</p>-->
  </div>
</template>

<script>
export default {
  name: "radio-tab-selector",
  props: {
    optionsTabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: "all",
    };
  },
  methods: {
    optionChanged(optionTab) {
      this.$emit("update:selected", this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-group {
  display: flex;
  border-bottom: 1px solid #e7e7ed;
  margin: 0px 10px 30px 10px !important;
  &-button {
    padding: 0.5rem 24px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
    text-align: center;
    margin-bottom: 0;
    color: #000;
    &.active {
      border-bottom: 2px solid #626262;
      background-color: transparent;
      color: #000;
      font-family: "Greycliff CF Bold", sans-serif;
    }
    input {
      display: none;
    }
  }
}
</style>
